import "@babel/polyfill";
import "mutationobserver-shim";
import { Amplify } from 'aws-amplify';
import awsconfig from './aws-exports';
import '@aws-amplify/ui-components';
import {
  applyPolyfills,
  defineCustomElements,
} from '@aws-amplify/ui-components/loader';
import Vue from "vue";
import "./plugins/bootstrap-vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import 'whatwg-fetch';
import vueCustomElement from 'vue-custom-element';
import VueAnnouncer from '@vue-a11y/announcer';
import VueSessionStorage from 'vue-sessionstorage';
import VueCookie from "vue-cookie";
import moment from 'moment';
import VueGtag from "vue-gtag";

Vue.config.productionTip = false;


Vue.use(vueCustomElement);
Vue.use(VueAnnouncer);
Vue.use(VueSessionStorage);
Vue.use(VueCookie);
Vue.prototype.moment = moment;
Vue.prototype.$hostname = "https://www.first5sc.org";


Amplify.configure(awsconfig);

Vue.config.ignoredElements = [/amplify-\w*/];

Vue.use(VueGtag, {
  config: { id: "G-0BXSNF9RLN" }
});

applyPolyfills().then(() => {
  defineCustomElements(window);
});

App.store = store
App.router = router
Vue.customElement('common-app-vue', App)